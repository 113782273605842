import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Our Commitment' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        We take our commitment to our users seriously. We believe that open communication is key to building a strong relationship with our users and partners. We're committed to providing exceptional customer service and ensuring that all inquiries are addressed in a timely and professional manner.
        contact us.
      </Text>
    </Card>
  </Section>
)

export default Commitment
